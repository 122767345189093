import Vue from 'vue'
import store from './store'

Vue.directive('user-scope-or', {
    bind(el, binding) {
        let userResource = store.getters.userResource

        if (!userResource) {
            el.style.display = 'none'
            return undefined
        }

        let perms = binding.value || []
        let compareScopeFn = binding.modifiers.all ? userResource.hasScopeAll : userResource.hasScopeAny
        if (!userResource || !compareScopeFn.apply(userResource, perms)) {
            // Allow .hide modifier
            if (binding.modifiers.hide) {
                el.style.display = 'none'
            }
            // Allow .disabled modifier
            if (binding.modifiers.disabled) {
                switch (el.tagName.toLowerCase()) {
                    case 'a':
                        el.className += ' disabled'
                        break
                    case 'button':
                    case 'input':
                    case 'textarea':
                        el.disabled = true
                        break
                }
            }
        }
    },
})

Vue.directive('page-title', {
    bind(el, binding) {
        store.commit('setPageTitle', binding.value)
    },
})
