import Vue from 'vue'
import BootstrapVue, { BootstrapVueIcons } from 'bootstrap-vue'
import SigmaPlugin from '@sigmacloud/sigma-client/dist/components/plugins/sigma'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import { LicenseManager } from 'ag-grid-enterprise'
import VueSignaturePad from 'vue-signature-pad'

const AG_GRID_LICENSE_KEY =
    'CompanyName=Revolution Payroll,LicensedGroup=ProBooks,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-021824,ExpiryDate=4_December_2022_[v2]_MTY3MDExMjAwMDAwMA==0723da76405e8e4969df68bfe317209f'
LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(SigmaPlugin)
Vue.use(VueSignaturePad)
