import Vue from 'vue'
import Vuex from 'vuex'
import { hostnameToInstanceName } from '@sigmacloud/sigma-client/dist/util/instances'
import Sigma from '@sigmacloud/sigma-client'
import UserResource from '@sigmacloud/sigma-client/dist/resources/user'

export { mapGetters, mapMutations, mapActions, mapState } from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        pageTitle: null,
        resourceName: null,
        appIsLoading: false,
        // Instances are managed by routes -- setting it here is only for other components
        instanceURL: null,
        // User resource is managed by routes and subsequently loadStoreInstanceDependencies -- don't change it manually
        userResource: null,
        userAlerts: [], //alerts
        userNotifications: [], // toasts
    },
    mutations: {
        setPageTitle(state, title) {
            let docTitle = title ? `${title} | ProBooks` : 'ProBooks'
            state.pageTitle = docTitle
            document.title = docTitle
        },
        setResourceName(state, payload) {
            state.resourceName = payload
        },
        loading(state) {
            state.appIsLoading = true
        },
        done(state) {
            state.appIsLoading = false
        },
        /**
         * Do not implement this method!
         */
        setInstanceURL() {
            throw new Error('Instance URL should be set by router!')
        },
        /**
         * Do not implement this method!
         */
        setUserResource() {
            throw new Error("User Resource can't be set manually in store, use router!")
        },
        setUserAlerts(state, payload) {
            state.userAlerts = payload
        },
        pushUserAlert(state, payload) {
            state.userAlerts.push(payload)
        },
        removeUserAlert(state, userAlertId) {
            state.userAlerts = state.userAlerts.filter((userAlert) => userAlert.id !== userAlertId)
        },
        setUserNotifications(state, payload) {
            state.userNotifications = payload
        },
        pushUserNotifications(state, payload) {
            state.userNotifications.push(payload)
        },
        removeUserNotifications(state, userNotificationId) {
            state.userNotifications = state.userNotifications.filter((userNotification) => userNotification.id !== userNotificationId)
        },
    },
    getters: {
        pageTitle(state) {
            return state.pageTitle
        },
        resourceName(state) {
            return state.resourceName
        },
        instanceURL(state) {
            return state.instanceURL
        },
        instanceName(state) {
            return hostnameToInstanceName(state.instanceURL)
        },
        loadingState(state) {
            return state.appIsLoading
        },
        userResource(state) {
            return state.userResource
        },
        userAlerts(state) {
            return state.userAlerts
        },
        userNotifications(state) {
            return state.userNotifications
        },
    },
    actions: {
        /**
         * Load dependencies that are required when instance is loaded/changed
         * It's important to keep this operation MINIMAL
         * @param {*} context
         * @param {*} options
         */
        async loadStoreInstanceDependencies(context, options) {
            if (options.hostname && options.allowSetInstanceURLInStore !== true) {
                throw new Error('Instance URL should be set by router!')
            }

            if (options.hostname) {
                context.state.instanceURL = options.hostname
            }

            if (Sigma.client.token) {
                try {
                    let payload = Sigma.client.getTokenPayload()
                    context.commit('loading')
                    let userResource = await UserResource.detail(payload.user_id)
                    context.state.userResource = userResource
                    await userResource.retrievePermissions()
                } catch (e) {
                    console.error(e)
                    throw e
                } finally {
                    context.commit('done')
                }
            }
        },
    },
    modules: {},
})
