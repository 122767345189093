import Vue from 'vue'
import VueRouter from 'vue-router'
import Sigma from '@sigmacloud/sigma-client'
import store from '../store'
import { allRoutes } from '../views/apps/routes'
import { getLocalToken, setInstance } from '@sigmacloud/sigma-client/dist/components/plugins/sigma'
import { instanceNameToHostname } from '@sigmacloud/sigma-client/dist/util/instances'
import { logger } from '@sigmacloud/sigma-client/dist/logger'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        components: {
            nav: () => import('../views/apps/NavBar.vue'),
            body: () => import('../views/Home.vue'),
        },
    },
    {
        path: '/apps/:instanceName',
        children: allRoutes(),
        components: {
            nav: () => import('../views/apps/NavBar.vue'),
            body: () => import('../views/apps/Wrapper.vue'),
        },
        async beforeEnter(to, from, next) {
            if (!to.params.instanceName) {
                throw new Error(`Tried to load "${to.path}" subroute without "instanceName" parameter!`)
            }

            // Update instances for wrapper
            try {
                let hostname = instanceNameToHostname(to.params.instanceName)
                let token = getLocalToken(hostname)

                if (Sigma.client.config.baseURL !== hostname) {
                    setInstance(hostname, token)
                    await store.dispatch('loadStoreInstanceDependencies', { hostname, token, allowSetInstanceURLInStore: true })
                }

                next()
            } catch (e) {
                console.error(e)
                if (to.meta.requiresToken === false) {
                    logger.info('Route does not require a token')
                    next()
                } else {
                    logger.warn('Route requires a token')
                    next({ name: 'login', params: { instanceName: to.params.instanceName }, query: { next: to.path } })
                }
            }
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    // Update page title
    store.commit('setPageTitle', to.meta.title)
    next()
})

export default router
