import { readableNameFromResourceClass } from '@sigmacloud/sigma-client/dist/util/resources/name'
import { toCamelCase } from '@sigmacloud/sigma-client/dist/util/string'
import { pluralResourceName } from '@sigmacloud/sigma-client/dist/util/resources/name'

export function allRoutes() {
    let namedRoutes = [
        {
            path: '/apps/:instanceName/home',
            name: 'appHome',
            components: {
                body: () => import('./Home.vue'),
            },
        },
        {
            path: '/apps/:instanceName/login',
            name: 'login',
            components: {
                body: () => import('./Login.vue'),
            },
            meta: {
                requiresToken: false,
            },
        },
        {
            path: '/apps/:instanceName/help',
            name: 'help',
            components: {
                body: () => import('./Help.vue'),
            },
        },
        {
            path: '/apps/:instanceName/programs/pos/approvals',
            name: 'poApprovals',
            components: { body: () => import('./programs/pos/Approvals.vue') },
        },
        {
            path: '/apps/:instanceName/programs/checks/signatures',
            name: 'checkSignatures',
            components: { body: () => import('./programs/checks/Signatures.vue') },
        },
        {
            path: '/apps/:instanceName/transactions/payrolls/printable',
            name: 'payrollResourcePrintableList',
            props: { body: { resourceClassName: 'PayrollResource', linkColumns: ['id'] } },
            components: { body: () => import('../../components/transactions/PayrollResourcePrintable.vue') },
        },
        {
            path: '/apps/:instanceName/reports/',
            name: 'reportList',
            props: { body: { resourceClassName: 'ReportRenderResource', linkColumns: ['name'] } },
            components: { body: () => import('./ResourceList') },
        },
        {
            path: '/apps/:instanceName/reports/:id',
            name: 'reportDetail',
            props: { body: (route) => ({ resourceClassName: 'ReportRenderResource', id: route.params.id }) },
            components: { body: () => import('./ResourceDetail') },
        },
    ]
    let systemRoutes = [
        { resourceClassName: 'AccountResource', linkColumns: ['code'] },
        { resourceClassName: 'AddressResource', linkColumns: ['id'] },
        { resourceClassName: 'AgentResource', linkColumns: ['code'] },
        { resourceClassName: 'AgreementResource', linkColumns: ['code'] },
        { resourceClassName: 'AssignmentResource', linkColumns: ['${employee.last_name}, ${employee.first_name}'] },
        { resourceClassName: 'BankResource', linkColumns: ['code', 'name'] },
        { resourceClassName: 'BillableTypeResource', linkColumns: ['code', 'name'] },
        { resourceClassName: 'BudgetResource', linkColumns: ['code'] },
        { resourceClassName: 'CityResource', linkColumns: ['name', 'code'] },
        { resourceClassName: 'ClientResource', linkColumns: ['name', 'code'] },
        { resourceClassName: 'CompanyResource', linkColumns: ['code'] },
        { resourceClassName: 'CountryResource', linkColumns: ['name'] },
        { resourceClassName: 'CountryTaxCodeResource', linkColumns: ['code'] },
        { resourceClassName: 'CurrencyResource', linkColumns: ['code', 'country.name'] },
        { resourceClassName: 'EEOClassResource', linkColumns: ['code'] },
        { resourceClassName: 'EmployeeResource' },
        { resourceClassName: 'EmployeeDirectDepositRuleResource', linkColumns: ['direct_deposit_account.name'] },
        { resourceClassName: 'EmployeeTypeResource', linkColumns: ['code'] },
        { resourceClassName: 'EpisodeResource', linkColumns: ['episode_number'] },
        { resourceClassName: 'DepartmentResource', linkColumns: ['code'] },
        { resourceClassName: 'DepositAccountResource', linkColumns: ['name'] },
        { resourceClassName: 'FeeResource', linkColumns: ['code', 'name'] },
        { resourceClassName: 'FeeCategoryResource', linkColumns: ['code'] },
        { resourceClassName: 'FeeGroupResource', linkColumns: ['name'] },
        { resourceClassName: 'ActualFeeRateResource', linkColumns: ['name'] },
        { resourceClassName: 'FreeFieldResource', linkColumns: ['code'] },
        { resourceClassName: 'FLSATypeResource', linkColumns: ['code'] },
        { resourceClassName: 'GroupResource', linkColumns: ['name'] },
        { resourceClassName: 'IndustryResource', linkColumns: ['code'] },
        { resourceClassName: 'LaborUnionResource', linkColumns: ['name'] },
        { resourceClassName: 'LaborUnionLocalResource', linkColumns: ['local'] },
        { resourceClassName: 'InsuranceResource', linkColumns: ['code'] },
        { resourceClassName: 'LegislativeTypeResource', linkColumns: ['code'] },
        { resourceClassName: 'LocationResource', linkColumns: ['code'] },
        { resourceClassName: 'MarkupResource', linkColumns: ['code', 'name'] },
        { resourceClassName: 'OCCCodeResource', linkColumns: ['code'] },
        { resourceClassName: 'PayCodeResource', linkColumns: ['code', 'name'] },
        { resourceClassName: 'PayCodeTypeResource', linkColumns: ['code'] },
        { resourceClassName: 'ProductionResource', linkColumns: ['code'] },
        { resourceClassName: 'ProductionTypeResource', linkColumns: ['code'] },
        { resourceClassName: 'ProjectResource', linkColumns: ['number'] },
        { resourceClassName: 'ProjectFeeAccumulationGroupResource', linkColumns: ['code'] },
        { resourceClassName: 'ScheduleResource', linkColumns: ['code'] },
        { resourceClassName: 'SalesRepResource', linkColumns: ['name'] },
        { resourceClassName: 'SeriesResource', linkColumns: ['code'] },
        { resourceClassName: 'SetResource', linkColumns: ['code'] },
        { resourceClassName: 'StateTaxpayerNumberResource', linkColumns: ['client.name'] },
        { resourceClassName: 'APISettingResource', linkColumns: ['name'], linkPrimaryKey: 'name' },
        { resourceClassName: 'StateResource', linkColumns: ['name', 'code'] },
        { resourceClassName: 'TitleResource', linkColumns: ['name'] },
        { resourceClassName: 'UserResource', linkColumns: ['username'] },
        { resourceClassName: 'UserSourceCodeResource', linkColumns: ['code'] },
        { resourceClassName: 'VendorResource', linkColumns: ['code'] },
        { resourceClassName: 'WCClassificationResource', linkColumns: ['code'] },
        { resourceClassName: 'W9Resource', linkColumns: ['source'] },
        { resourceClassName: 'SignatureResource', linkColumns: ['name'] },
    ]
    let transactionRoutes = [
        { resourceClassName: 'AccountsPayableResource', linkColumns: ['ledger'] },
        { resourceClassName: 'BatchResource', linkColumns: ['name'] },
        { resourceClassName: 'BillableItemResource', linkColumns: ['code'] },
        { resourceClassName: 'PurchaseOrderResource', linkColumns: ['id'] },
        { resourceClassName: 'CheckResource', linkColumns: ['id'] },
        { resourceClassName: 'CheckApprovalResource', linkColumns: ['id'] },
        { resourceClassName: 'PayrollResource', linkColumns: ['id'] },
    ]
    let notFoundRoute = [
        {
            path: '/apps/:instanceName/:pathMatch(.*)*',
            name: 'notFound',
            components: {
                body: () => import('../NotFound.vue'),
            },
        },
    ]
    return [...namedRoutes, ...generateResourceRoutes(systemRoutes, 'system'), ...generateResourceRoutes(transactionRoutes, 'transactions'), ...notFoundRoute]
}

function generateResourceRoutes(routes, path) {
    let generateResourceEndpoints = ({ path, resourcePath, camelCaseResource, resourceClassName, linkPrimaryKey, linkColumns }) => [
        {
            path: `/apps/:instanceName/${path}/${resourcePath}`,
            name: `${camelCaseResource}List`,
            props: { body: { resourceClassName, linkPrimaryKey, linkColumns } },
            components: { body: () => import('./ResourceList') },
        },
        {
            path: `/apps/:instanceName/${path}/${resourcePath}/create`,
            name: `${camelCaseResource}Create`,
            props: { body: { resourceClassName } },
            components: { body: () => import('./ResourceDetail') },
        },
        {
            path: `/apps/:instanceName/${path}/${resourcePath}/:id`,
            name: `${camelCaseResource}Detail`,
            props: { body: (route) => ({ resourceClassName, id: route.params.id }) },
            components: { body: () => import('./ResourceDetail') },
        },
    ]

    return generateRoutes(routes, path, generateResourceEndpoints)
}

function generateRoutes(routes, path, endpointFn) {
    return routes.reduce((generatedRoutes, { resourceClassName, linkPrimaryKey, linkColumns }) => {
        let camelCaseResource = toCamelCase(readableNameFromResourceClass(resourceClassName).replace(' ', ''))
        let resourcePath = pluralResourceName(camelCaseResource.toLowerCase())

        generatedRoutes.push(...endpointFn({ path, resourcePath, camelCaseResource, resourceClassName, linkPrimaryKey, linkColumns }))
        return generatedRoutes
    }, [])
}
