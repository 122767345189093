import Vue from 'vue'
import './plugins'
import App from './App.vue'
import router from './router'
import store from './store'
import Sigma from '@sigmacloud/sigma-client'
import { logger } from '@sigmacloud/sigma-client/dist/logger'
import { IS_DEV } from './config'
import { hostnameToInstanceName } from '@sigmacloud/sigma-client/dist/util/instances'
import './directives'
import './assets/main.scss'
import './assets/styles.scss'

// Set a handler for when tokens get expired
Sigma.events.on('client.set', (client) => {
    logger.info(`Client changed to ${client.hostname}`)
    client.loadAPIMeta()
    client.events.on('token.expires', () => {
        logger.warn('Token expired, redirecting...')
        let instanceName = hostnameToInstanceName(client.hostname)
        if (router.currentRoute.name !== 'login') {
            router.push({ name: 'login', params: { instanceName } })
        }
    })
})

if (!IS_DEV && !window.location.href.match(/^https:/)) {
    window.location.href = window.location.href.replace(/^http:/, 'https:')
} else if (IS_DEV) {
    // Ignore Vue Warn in Sigma Client
    const { enableIgnoreVueWarn } = require('@sigmacloud/sigma-client/dist/components/plugins')
    enableIgnoreVueWarn()
    // Ignore Vue Warn in local Vue
    Vue.config.warnHandler = () => {}
    // Set log level
    logger.level = localStorage.getItem('loglevel') || 'warn'
}

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
