<script>
import Vue from 'vue'
import router from './router'
import './assets/styles.scss'

export default Vue.extend({
    computed: {
        userAlerts: {
            get: ({ $store }) => $store.getters.userAlerts,
            set(value) {
                console.log(value)
                this.$store.commit('setUserAlerts', value)
            },
        },
        resourceName: {
            get: ({ $store }) => $store.getters.resourceName,
        },
        loadingMaskClasses() {
            let classes = ['loading-mask']
            if (!this.$store.getters.loadingState) {
                classes.push('hidden')
            }
            return classes
        },
    },

    methods: {
        openResource(path) {
            router.push(path)
        },
    },
})
</script>

<template>
    <div id="app">
        <user-alert :key="userAlerts.length" v-model="userAlerts" @openResource="openResource"
            ><template v-if="resourceName" #resourceName>{{ resourceName }}</template></user-alert
        >
        <router-view name="nav" />
        <router-view name="body" class="app-body" />
        <router-view name="footer" />
        <!-- Main App Loading Mask -->
        <div :class="loadingMaskClasses">
            <b-row class="text">
                <b-col cols="4" offset="4">
                    <b-icon icon="circle-fill" animation="throb" font-scale="4"></b-icon><br />
                    <strong>PRO</strong>BOOKS is loading...
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<style lang="scss">
#app {
    color: #2c3e50;
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    .app-body {
        margin-top: 40px;
    }
}
</style>
